import clsx from 'clsx'
import React from 'react'

import classes from './Input.module.css'

export default function Input (props) {
  const { type } = props

  if (type === 'textfield') {
    const { className, ...rest } = props

    return (
      <textarea
        className={clsx(classes.root, classes.textfield, className)}
        {...rest}
      />
    )
  }

  if (type === 'radio') {
    const { className, children, ...rest } = props

    return (
      <label className={classes.radioRoot}>
        <input
          className={clsx(classes.root, className)}
          {...rest}
        />

        <span className={classes.radio}>
          <span className={classes.check}>
            ✓
          </span>
        </span>

        <span className={classes.label}>
          {children}
        </span>
      </label>
    )
  }

  const { className, ...rest } = props

  return (
    <input
      className={clsx(classes.root, className)}
      {...rest}
    />
  )
}
