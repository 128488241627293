import React, { useState, useRef } from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import Messenger from "../assets/svg/facebook-messenger-brands.svg"
import Facebook from "../assets/svg/facebook-square-brands.svg"
import Youtube from "../assets/svg/youtube-brands.svg"
import Instagram from "../assets/svg/instagram-brands.svg"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import clsx from "clsx"
import RoundedButton from "../components/RoundedButton"
import Input from "../atoms/Input"
import classes from "./LetsChat.module.css"

import PayPal from "../assets/web_resources/paypal1.png"

import GipsyAtParaisoImage from "../gatsbyImages/GipsyAtParaisoImage"
import StripeImage from "../gatsbyImages/StripeImage"
import VisaImage from "../gatsbyImages/VisaImage"
import MCImage from "../gatsbyImages/MCImage"
import AmericanExpressImage from "../gatsbyImages/AmericanExpressImage"
import DiscoverImage from "../gatsbyImages/DiscoverImage"

function LetsChat({ start, end }) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
  const [submitted, setSubmitted] = useState(false)
  const form = useRef()

  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    filmmaker: "",
    message: "",
  })

  function handleChange(e) {
    const {
      target: { name, value },
    } = e

    setValues({ ...values, [name]: value })
  }

  function handleSubmit(e) {
    e.preventDefault()

    const formData = new FormData(form.current)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch(error => alert(error))
  }

  const { scrollY } = useViewportScroll()
  const [zIndex, setzIndex] = useState(-1)

  scrollY.onChange(x => {
    if (x > start) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })

  const middle = start + (end - start) / 2

  const styleOpacity = useTransform(
    scrollY,
    [start, start + 1, middle, middle + 1],
    [0.6, 0.4, 0.2, 0]
  )

  const opacityOffAfterMiddle = useTransform(
    scrollY,
    [start, start + 1, middle, middle + 1],
    [0, 1, 1, 1]
  )

  const bubbleOneStyle = {
    x: useTransform(
      scrollY,
      [start, middle],
      isMobile ? ["-41.5%", "-50%"] : ["-54%", "-75%"]
    ),
    y: useTransform(
      scrollY,
      [start, middle],
      isMobile ? ["-40%", "-44%"] : ["-50.2%", "-52%"]
    ),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const bubbleTwoStyle = {
    x: useTransform(
      scrollY,
      [start, middle],
      isMobile ? ["-50%", "-45%"] : ["-55%", "-50%"]
    ),
    y: useTransform(scrollY, [start, middle], ["-48%", "-52%"]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const style1 = {
    y: useTransform(scrollY, [start, middle], [1000, 510]),
    transformOrigin: "center",
    opacity: styleOpacity,
  }
  const style2 = {
    y: useTransform(scrollY, [start, middle], [1000, 540]),
    transformOrigin: "center",
    opacity: styleOpacity,
  }
  const style3 = {
    y: useTransform(scrollY, [start, middle], [1000, 570]),
    transformOrigin: "center",
    opacity: styleOpacity,
  }

  const infoStyle1 = {
    y: useTransform(scrollY, [start, middle], [1000, 530]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }
  const infoStyle2 = {
    y: useTransform(scrollY, [start, middle], [1000, 560]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }
  const infoStyle3 = {
    y: useTransform(scrollY, [start, middle], [1000, 590]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const FormGipsyAtParaisoStyle = {
    x: useTransform(
      scrollY,
      [start, middle],
      isMobile ? ["-5%", "-4%"] : ["50%", "0%"]
    ),
    y: useTransform(
      scrollY,
      [start, middle],
      isMobile ? [700, -70] : [-600, -80]
    ),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const dropUsStyle = {
    x: useTransform(
      scrollY,
      [start, middle],
      isMobile ? ["50%", "6%"] : ["50%", "0%"]
    ),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const formStyle1 = {
    y: useTransform(scrollY, [start, middle], [300, 0]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const formStyle2 = {
    y: useTransform(scrollY, [start, middle], [400, 0]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }
  const formStyle3 = {
    y: useTransform(scrollY, [start, middle], [500, 0]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }
  const formStyle4 = {
    y: useTransform(scrollY, [start, middle], [600, 0]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }
  const formStyle5 = {
    y: useTransform(scrollY, [start, middle], [700, 0]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }
  const formStyle6 = {
    y: useTransform(scrollY, [start, middle], [800, 0]),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const SafePaymentStyle = {
    x: isMobile ? 230 : 240,
    y: isMobile ? 590 : 600,
    scale: 0.6,
    transformOrigin: "center",
    color: "white",
    width: "300px",
  }

  const mobileFAQStyle = {
    y: 650,
    opacity: opacityOffAfterMiddle,
  }

  return (
    <div
      style={{
        zIndex: zIndex,
      }}
      className={classes.letsChatContainer}
    >
      <motion.div
        style={bubbleOneStyle}
        className={clsx(classes.formBubbleOne, classes.absoluteCenter)}
      >
        <Messenger className={classes.svg1} />
        <Instagram className={classes.svg2} />
        <Youtube className={classes.svg3} />
        <Facebook className={classes.svg4} />

        <motion.div style={style1} className={classes.info}>
          <Link to="/faq">
            <div className={classes.FAQ1}>FAQ</div>
          </Link>
        </motion.div>
        <motion.div style={style2} className={classes.info}>
          <Link to="/privacy-policy">
            <div className={classes.privacy1}>PRIVACY</div>
          </Link>
        </motion.div>

        <motion.div style={style3} className={classes.info}>
          <Link to="/terms-conditions">
            <div className={classes.terms1}>T&C</div>
          </Link>
        </motion.div>

        <motion.div style={infoStyle1} className={classes.info}>
          <Link to="/faq">
            <div className={classes.FAQ}>FAQ</div>
          </Link>
        </motion.div>
        <motion.div style={infoStyle2} className={classes.info}>
          <Link to="/privacy-policy">
            <div className={classes.privacy}>PRIVACY</div>
          </Link>
        </motion.div>

        <motion.div style={infoStyle3} className={classes.info}>
          <Link to="/terms-conditions">
            <div className={classes.terms}>T&C</div>
          </Link>
        </motion.div>
      </motion.div>

      <motion.div
        style={bubbleTwoStyle}
        className={clsx(classes.formBubbleTwo, classes.absoluteCenter)}
      >
        <motion.div style={dropUsStyle} className={classes.formDropUsALine}>
          Drop us a line
        </motion.div>

        <motion.div style={dropUsStyle}>
          <div className={classes.formLetsChat}>Let's chat!</div>
        </motion.div>

        {submitted ? (
          <div className={clsx(classes.formSubmitted, classes.absoluteCenter)}>
            <p>Thank you for getting in touch.</p>
            <p>We will reach back as soon as possible</p>
            <p>In the meantime, you can check out YouTube</p>
            <p>or Instagram page</p>
          </div>
        ) : (
          <form
            ref={form}
            data-netlify="true"
            method="POST"
            className={clsx(classes.form, classes.absoluteCenter)}
            onSubmit={handleSubmit}
            netlify
          >
            <input type="hidden" name="form-name" value="message" />

            <motion.div style={formStyle1}>
              <Input
                required
                placeholder="What's your name?"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
            </motion.div>

            <motion.div style={formStyle2}>
              <Input
                required
                placeholder="Your e-mail"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
            </motion.div>

            <motion.div style={formStyle3}>
              <div className={classes.formRadios}>
                <div className={classes.formAreYouAFilmmaker}>
                  Are you a filmmaker?
                </div>

                <Input
                  required
                  name="filmmaker"
                  type="radio"
                  value="yes"
                  checked={values.filmmaker === "yes"}
                  onChange={handleChange}
                >
                  Yes
                </Input>

                <Input
                  required
                  name="filmmaker"
                  type="radio"
                  value="no"
                  checked={values.filmmaker === "no"}
                  onChange={handleChange}
                >
                  No
                </Input>
              </div>
            </motion.div>

            <motion.div style={formStyle4}>
              <Input
                placeholder="Subject (optional)"
                name="subject"
                value={values.subject}
                onChange={handleChange}
              />
            </motion.div>

            <motion.div style={formStyle5}>
              <label>
                <span className={classes.formYourMessage}>
                  Tell us about it
                </span>

                <Input
                  required
                  type="textfield"
                  rows="3"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                />
              </label>
            </motion.div>

            <motion.div style={formStyle6}>
              <div className="flex justify-center">
                <RoundedButton color="white" type="submit">
                  PRESS ME
                </RoundedButton>
              </div>
            </motion.div>
          </form>
        )}

        <motion.div style={SafePaymentStyle}>
          Safe and secure payments via
          <div className={classes.paymentGrid}>
            <div>{<StripeImage />}</div>
            <div>
              <img
                src={PayPal}
                alt=""
                width="50px"
                style={{ margin: "auto" }}
              />
            </div>

            <div>{<VisaImage />}</div>
            <div>{<MCImage />}</div>
            <div>
              <AmericanExpressImage />
            </div>
            <div>{<DiscoverImage />}</div>
          </div>
        </motion.div>

        <motion.div style={mobileFAQStyle}>
          <div className={classes.mobileFAQ}>
            <p>
              <Link to="/faq">FAQ</Link>
            </p>

            <p>
              <Link to="/privacy-policy">PRIVACY POLICY</Link>
            </p>
            <p>
              <Link to="/terms-conditions">TERMS & CONDITIONS</Link>
            </p>
          </div>
        </motion.div>

        <motion.div
          style={FormGipsyAtParaisoStyle}
          className={classes.formGipsyAtParaiso}
        >
          <GipsyAtParaisoImage />
        </motion.div>
      </motion.div>
    </div>
  )
}

export default LetsChat
