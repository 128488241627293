import React, { useState } from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import { Link } from "gatsby"
import clsx from "clsx"
import philosophy from "../assets/philosophy1.gif"
import stepsFromRange from "../helpers/stepsFromRange"
import getRatio from "../helpers/getRatio"
import RoundedButton from "./RoundedButton"
import classes from "./WhoSection.module.css"
import { useMediaQuery } from "react-responsive"

function WhoSection({ start, middle, out, end }) {
  const { scrollY } = useViewportScroll()
  const [zIndex, setzIndex] = useState(0)
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  scrollY.onChange(x => {
    if (x > start && x < end) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })

  const range = [start, end]

  const middleRatio = getRatio(start, middle, end)

  const outRatio = getRatio(start, out, end)

  const opacityOffAfterMiddle = useTransform(
    scrollY,
    [middle, end, end + 1],
    [1, 1, 0]
  )

  const opacityInOut = useTransform(
    scrollY,
    stepsFromRange(range, [0, 0.1, middleRatio, outRatio, 0.8, 1]),
    [0, 0.8, 1, 1, 0.8, 0]
  )

  const bubbleScale = useTransform(
    scrollY,
    stepsFromRange(range, [0, middleRatio]),
    [0, 1]
  )

  const bubbleOneStyle = {
    scale: bubbleScale,
    x: useTransform(
      scrollY,
      [3900, middle, out, end],
      isMobile
        ? ["-56%", "-56%", "-56%", "-56%"]
        : ["-10%", "-40%", "-40%", "-55%"]
    ),
    y: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile
        ? ["-40%", "-40%", "-40%", "-40%"]
        : [
            "calc(-50% - 20px)",
            "calc(-50% - 20px)",
            "calc(-50% - 20px)",
            "calc(-50% - 20px)",
          ]
    ),
    transformOrigin: "center",
    opacity: useTransform(
      scrollY,
      [middle - 1, middle, out, middle + 210, end + 1],
      isMobile ? [0, 1, 1, 0, 0] : [0, 1, 1, 1, 0]
    ),
  }

  const bubbleTwoStyle = {
    scale: bubbleScale,
    x: useTransform(
      scrollY,
      [3900, middle, out, end],
      isMobile
        ? ["-50%", "-50%", "-50%", "-50%"]
        : ["-50%", "-52.5%", "-52.5%", "-55%"]
    ),
    y: "-50%",
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const whoTextStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["100%", "-10%", "-10%", "-100%"]
    ),
    y: -30,
    opacity: opacityInOut,
  }

  const whoIsTheMadJonTextStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["115%", "15%", "15%", "-85%"]
        : ["-100%", "-5%", "-5%", "100%"]
    ),
    y: -40,
    opacity: opacityInOut,
  }

  const whoCreativeMindsTextStyle = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [60, 60, 60] : [60, 60, -800]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 1, 1, 0] : [0, 1, 1, 1]
    ),
    opacity: opacityInOut,
  }

  const whoPindarQuoteTextStyle = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [-400, -400, -400] : [-400, -400, -800]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 1, 1, 0] : [0, 1, 1, 1]
    ),

    opacity: opacityInOut,
  }

  const whoPindarQuoteReferenceText = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [-390, -390, -340] : [-400, -400, -800]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 1, 1, 0] : [0, 1, 1, 1]
    ),
    transformOrigin: "bottom",
    opacity: opacityInOut,
  }

  const whoAboutButtonContainerStyle = {
    display: "inline-block",
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [250, -470, -470, -800]
    ),
    scale: 0.85,
    opacity: opacityInOut,
  }

  const whoPhilosophyStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [-430, -430, -430] : [-450, -450, -800]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["50%", "-50%", "-50%", "-150%"]
    ),
    scale: useTransform(scrollY, [middle, out, end], [0.8, 0.8, 1.5]),
    opacity: opacityInOut,
  }

  return (
    <div
      style={{
        zIndex: zIndex,
      }}
      className={classes.whoContainer}
    >
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <motion.div
          style={bubbleOneStyle}
          className={clsx(classes.whoBubbleOne, classes.absoluteCenter)}
        />

        <motion.div
          style={bubbleTwoStyle}
          className={clsx(classes.whoBubbleTwo, classes.absoluteCenter)}
        />

        <div className={clsx(classes.whoTextContainer, classes.absoluteCenter)}>
          <motion.div style={whoTextStyle} className={classes.whoText}>
            <span className={classes.whoW}>w</span>ho?
          </motion.div>

          <motion.div
            style={whoIsTheMadJonTextStyle}
            className={classes.whoIsTheMadJonText}
          >
            is the Mad Jon
          </motion.div>

          <motion.span
            style={whoCreativeMindsTextStyle}
            className={classes.whoCreativeMindsText}
          >
            Creative minds that use <span>storytelling</span> to edit your
            footage and to express human <span>emotion</span>. We will tailor
            and give you the <span>experience</span> of a great story. <br />
            <br /> There's <span>beauty</span> in your moments and <br /> we
            know how to look for it.
            <br />
            <br />
            We firmly believe that there is no story <br /> like another, so
            please imagine the <br /> <span>best story</span> there is.
            <span>It's yours!</span>
          </motion.span>

          <motion.span
            style={whoPindarQuoteTextStyle}
            className={classes.whoPindarQuoteText}
          >
            "The soul never thinks <br /> without a picture."
          </motion.span>

          <motion.span
            style={whoPindarQuoteReferenceText}
            className={classes.whoPindarQuoteReferenceText}
          >
            -Aristotle-
          </motion.span>

          <motion.span style={whoAboutButtonContainerStyle}>
            <RoundedButton
              className={classes.whoAboutButton}
              color="secondary"
              component={Link}
              to="/about"
            >
              KNOW MORE
            </RoundedButton>
          </motion.span>
        </div>

        <motion.img
          style={whoPhilosophyStyle}
          className={clsx(classes.whoPhilosophy, classes.absoluteCenter)}
          src={philosophy}
        />
      </div>
    </div>
  )
}

export default WhoSection
