import { motion, useTransform, useViewportScroll } from "framer-motion"
import React, { useState } from "react"
import stepsFromRange from "../helpers/stepsFromRange"
import getRatio from "../helpers/getRatio"

import styles from "./FreeStuff.module.css"
import { Link } from "gatsby"
import RoundedButton from "./RoundedButton"
import { useMediaQuery } from "react-responsive"

import downloadGif from "../assets/Download.gif"
import uploadGifMobile from "../assets/Upload b&w_mobile.gif"
import ArrowImage from "../gatsbyImages/ArrowImage"
import FreeImage from "../gatsbyImages/FreeImage"

import clsx from "clsx"
import TryItOut from "../gatsbyImages/TryItOut"

function FreeStuff({ start, middle, out, end }) {
  const { scrollY } = useViewportScroll()
  const [zIndex, setzIndex] = useState(0)
  const range = [start, end]
  const middleRatio = getRatio(start, middle, end)

  const outRatio = getRatio(start, out, end)
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  scrollY.onChange(x => {
    if (x > start && x < end) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })

  const opacityOffAfterMiddle = useTransform(
    scrollY,
    [middle - 1, middle, out, out + 1],
    [0, 1, 1, 0]
  )
  const opacityInOut = useTransform(
    scrollY,
    stepsFromRange(range, [0, 0.1, middleRatio, outRatio, 0.9, 1]),
    [0, 0.8, 1, 1, 0.8, 0]
  )

  const bigBlackBubbleStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
    transformOrigin: "0% 70%",
  }

  const backgroundBubbleStyles = {
    opacity: opacityOffAfterMiddle,
  }

  const mediumBlackBubbleStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
    transformOrigin: "100% 100%",
  }

  const smallBubbleStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
    transformOrigin: "100% 0%",
  }

  const roundsOfRevisionTextStyles = {
    opacity: useTransform(
      scrollY,
      [0, start, middle, out, out + 1, end],
      isMobile ? [0, 0.7, 1, 1, 1, 0] : [0, 0, 1, 1, 0, 0]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [1.6, 1, 1, 0] : [1.6, 1, 1, 1]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? [0, 170] : ["-400px", "150px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? [550, -80] : ["200px", "-90px"]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? ["0deg", "0deg", "100deg"] : ["0deg", "0deg", "0deg"]
    ),
  }

  const greatCopyrightMusicStyles = {
    opacity: useTransform(
      scrollY,
      [0, start, middle, out, out + 1, end],
      isMobile ? [0, 0.7, 1, 1, 1, 0] : [0, 0, 1, 1, 0, 0]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [1.6, 1, 1, 0] : [1.6, 1, 1, 1]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? [0, 100] : ["-400px", "80px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? [550, -100] : ["200px", "-100px"]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? ["0deg", "0deg", "100deg"] : ["0deg", "0deg", "0deg"]
    ),
  }

  const roundsOfRevisionStyles = {
    opacity: opacityInOut,
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [400, 0, 0, 400] : ["400px", "-10px", "-10px", "0px"]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [1.6, 1, 1, 1] : [1.6, 1, 1, 0]
    ),
    transformOrigin: "50% 150%",
  }

  const fastTurnaroundTextStyles = {
    opacity: opacityInOut,
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, -25, -25, -500] : ["400px", "-50px", "-50px", "0px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? ["400px", "0px"] : [0, 0]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      [1, 1, 0]
    ),
    transformOrigin: "50% 25%",
  }

  const perfectTextStyles = {
    opacity: useTransform(
      scrollY,
      stepsFromRange(range, [0, 0.1, middleRatio, outRatio, 0.9, 1]),
      isMobile ? [0, 0.8, 1, 1, 0.8, 0] : [0, 0.8, 1, 1, 0.1, 0]
    ),
    y: useTransform(scrollY, stepsFromRange(range, [0, middleRatio]), [
      "300px",
      "0px",
    ]),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [1, 1, 0] : [1, 1, 1]
    ),
  }

  const payTextStyles = {
    opacity: opacityInOut,
    y: useTransform(scrollY, stepsFromRange(range, [0, middleRatio]), [
      "500px",
      "140px",
    ]),
    transformOrigin: "50% 50%",
  }

  const downloadGifStyles = {
    opacity: opacityInOut,
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["300px", "-150px", "-150px", "-100px"]
        : ["50px", "-160px", "-160px", "50px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["-50px", "160px", "160px", "-300px"]
        : ["-200px", "160px", "160px", "500px"]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 0.35, 0.35, 1.4] : [0, 0.3, 0.3, 0]
    ),
  }

  const startProjectButtonStyles = {
    opacity: opacityInOut,
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["-500px", "0px", "0px", "-300px"]
    ),
    scale: 0.7,
  }

  const uploadGifStyles = {
    opacity: opacityInOut,
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["-300px", "20px", "20px", "-300px"]
        : ["-300px", "40px", "40px", "500px"]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [1.5, 1.2, 1.2, middleRatio]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["200px", "-200px", "-200px", "100px"]
        : ["200px", "-190px", "-190px", "0px"]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["0deg", "0deg", "0deg", "0deg"]
        : ["0deg", "0deg", "0deg", "200deg"]
    ),
  }

  const arrowImageStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 0.6, 0.6, 0]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["-125deg", "15deg", "15deg", "-125deg"]
    ),
    y: useTransform(scrollY, stepsFromRange(range, [0, middleRatio]), [
      "-30px",
      "-40px",
    ]),
    x: useTransform(scrollY, stepsFromRange(range, [0, middleRatio]), [
      "10px",
      "-10px",
    ]),
  }

  const tryItOutStyles = {
    opacity: opacityInOut,
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [-400, 10, 10, -100] : ["-400px", "0px", "0px", "-400px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["-400px", "0px", "0px", "-400px"]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [1.2, 0.5, 0.5, 0.2]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? ["0deg", "0deg", "0deg"] : ["0deg", "0deg", "-250deg"]
    ),
  }

  return (
    <div style={{ zIndex }} className={styles.freeStuffContainer}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <div className={styles.bigBlackBubbleContainer}>
          <motion.div
            className={styles.bigBlackBackgroundBubble}
            style={backgroundBubbleStyles}
          />
          <motion.div
            className={styles.blackBubble}
            style={bigBlackBubbleStyles}
          />
          <motion.div
            className={styles.roundsOfRevisionText}
            style={roundsOfRevisionTextStyles}
          >
            <h2 className={styles.rounds3}>3</h2>
            <p className={styles.roundsOfRevisionParas}>
              rounds
              <br />
              of
              <br />
              revisions
            </p>
          </motion.div>
          <motion.div style={greatCopyrightMusicStyles}>
            <p className={styles.greatCopyrightMusic}>
              GREAT
              <br />
              copyright
              <br />
              music.
            </p>
          </motion.div>
          <motion.div
            className={styles.roundsOfRevision}
            style={roundsOfRevisionStyles}
          >
            <div style={{ width: "300px" }}>
              <FreeImage />
            </div>
            <h2 className={styles.stuffGetting}>stuff you're gettin'</h2>
          </motion.div>
          <motion.p
            className={styles.fastTurnaroundText}
            style={fastTurnaroundTextStyles}
          >
            FAST turnaround
          </motion.p>
        </div>

        <div className={styles.mediumBlackBubbleContainer}>
          <motion.div
            className={styles.mediumBlackBackgroundBubble}
            style={backgroundBubbleStyles}
          />
          <motion.div
            className={styles.blackBubble}
            style={mediumBlackBubbleStyles}
          />

          <motion.div className={styles.tryItOut} style={tryItOutStyles}>
            <TryItOut />
          </motion.div>

          <motion.img
            src={downloadGif}
            className={styles.downloadGif}
            style={downloadGifStyles}
          />

          <motion.div className={styles.arrowImage} style={arrowImageStyles}>
            <ArrowImage />
          </motion.div>
          <RoundedButton
            className={styles.startYourProjectBtn}
            component={props => (
              <Link className={styles.startProjectButton} to="/categorization">
                <motion.button {...props} />
              </Link>
            )}
            color="secondary"
            style={startProjectButtonStyles}
          >
            PUSH HERE
          </RoundedButton>
        </div>
        <div className={styles.smallBlackBubbleContainer}>
          <motion.div
            className={styles.smallBlackBackgroundBubble}
            style={backgroundBubbleStyles}
          />
          <motion.div
            className={styles.blackBubble}
            style={smallBubbleStyles}
          />
          <motion.h2 className={styles.perfectText} style={perfectTextStyles}>
            ...when
            <br />
            it's perfect...
          </motion.h2>

          <motion.div className={clsx(styles.payText)} style={payTextStyles}>
            pay & download <br /> your <br /> unique edit
          </motion.div>

          <motion.img
            src={uploadGifMobile}
            className={styles.uploadGif}
            style={uploadGifStyles}
          />
        </div>
      </div>
    </div>
  )
}

export default FreeStuff
