import React, { useState } from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import { Link } from "gatsby"
import clsx from "clsx"

import getRatio from "../helpers/getRatio"
import stepsFromRange from "../helpers/stepsFromRange"
import RoundedButton from "./RoundedButton"
import classes from "./WhySection.module.css"

import PickUsImage from "../gatsbyImages/PickUsImage"
import CheckMeOutImage from "../gatsbyImages/CheckMeOut"

import { useMediaQuery } from "react-responsive"

function WhySection({ start, middle, out, end }) {
  const { scrollY } = useViewportScroll()
  const [zIndex, setzIndex] = useState(0)
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  scrollY.onChange(x => {
    if (x > start && x < end) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })

  const range = [start, end]

  const middleRatio = getRatio(start, middle, end)

  const outRatio = getRatio(start, out, end)

  const opacityInOut = useTransform(
    scrollY,
    stepsFromRange(range, [0, 0.1, middleRatio, outRatio, 0.8, 1]),
    [0, 0.8, 1, 1, 0.8, 0]
  )

  const bubbleOneStyle = {
    x: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile
        ? ["-50%", "-50%", "-50%", "-50%"]
        : [
            "calc(-50% - 180px)",
            "calc(-50% - 130px)",
            "calc(-50% - 130px)",
            "calc(-50% - 80px)",
          ]
    ),
    y: useTransform(
      scrollY,
      [middle, out, end],
      isMobile ? ["-60%", "-60%", "-40%"] : ["-53%", "-53%", "-53%"]
    ),
    transformOrigin: "center",
    opacity: useTransform(
      scrollY,
      [start, start + 1, middle - 1, middle, out, end + 1],
      isMobile ? [0, 0, 0, 1, 1, 0] : [0, 1, 1, 1, 1, 0]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
  }

  const bubbleTwoStyle = {
    x: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile
        ? ["-50%", "-50%", "-50%", "-50%"]
        : [
            "calc(-50% + (-20px))",
            "calc(-50% + (-30px))",
            "calc(-50% + (-30px))",
            "calc(-50% + (-40px))",
          ]
    ),
    y: "-50%",
    transformOrigin: "center",
    opacity: useTransform(
      scrollY,
      [start, start + 1, end - 2, end - 1],
      [0, 1, 1, 0]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
  }

  const whyTextStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [800, -240, -240, -800]
    ),
    y: isMobile ? -400 : -450,
    opacity: opacityInOut,
  }

  const whyParagraphsStyle = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [500, 27, 27, -800] : [500, -40, -40, 0]
    ),
    x: isMobile ? -200 : -320,
    scale: useTransform(scrollY, [start, middle, out, end], [1, 1, 1, 0]),
    opacity: opacityInOut,
  }

  const bestPriceStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [-800, -300, -300, 800] : [-800, -330, -330, 800]
    ),
    y: isMobile ? -130 : -180,
    opacity: opacityInOut,
  }

  const whyPortfolioButtonStyle = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [900, 250, 250, -900]
    ),
    x: isMobile ? -80 : -130,
    opacity: opacityInOut,
    scale: isMobile ? 1.9 : 1.2,
    borderRadius: "30px",
  }

  const whyPickUsStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [900, 225, 225, 225] : [500, 240, 240, 200]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [500, -385, -385, -1300] : [500, -400, -400, -570]
    ),
    scale: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile ? [1, 1, 1, 1] : [1, 1, 1, 0]
    ),
    opacity: opacityInOut,
  }

  const whyPriceTagStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [900, -120, -120, -900] : [500, 40, 40, 40]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [400, 150, 150, -800] : [-300, 40, 40, 70]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["-270deg", "30deg", "30deg", "-200deg"]
        : ["270deg", "0deg", "0deg", "10deg"]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [1, 1, 1, 1] : [0.8, 1, 1, 0]
    ),
    opacity: opacityInOut,
  }

  return (
    <div
      style={{
        zIndex: zIndex,
      }}
      className={classes.whyContainer}
    >
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <motion.div
          style={bubbleOneStyle}
          className={clsx(classes.whyBubbleOne, classes.absoluteCenter)}
        />
        <motion.div
          style={bubbleTwoStyle}
          className={clsx(classes.whyBubbleTwo, classes.absoluteCenter)}
        />

        <motion.span
          className={clsx(classes.whyText, classes.absoluteCenter)}
          style={whyTextStyle}
        >
          <span className="italic">
            <span className={clsx(classes.whyTextW)}>w</span>
            <span>hy</span>
          </span>
          ?
        </motion.span>

        <motion.div
          style={whyParagraphsStyle}
          className={clsx(classes.whyParagraphs, classes.absoluteCenter)}
        >
          <p className="-ml-24 md:-ml-12 mb-3">
            Are you tired of the same copy&paste edits? <br /> We got you!
          </p>
          <p className="-ml-20 md:-ml-8 mb-3">
            We're passionate about cinematography, <br /> and it shows.
          </p>
          <p className="-ml-16 md:ml-auto">
            Do you want an edit worthy of the big <br /> screens? You came to
            the right place.
          </p>
        </motion.div>

        <motion.div
          style={bestPriceStyle}
          className={clsx(classes.whyBestPrice, classes.absoluteCenter)}
        >
          <div>You get the best</div>
          <div style={{ marginLeft: 20 }}>story that $ can buy</div>
        </motion.div>

        <motion.div
          className={clsx(classes.whyPickUs, classes.absoluteCenter)}
          style={whyPickUsStyle}
        >
          <PickUsImage />
        </motion.div>

        <Link to="/categorization">
          <motion.div
            className={clsx(classes.whyPriceTag, classes.absoluteCenter)}
            style={whyPriceTagStyle}
          >
            <CheckMeOutImage />
          </motion.div>
        </Link>

        <RoundedButton
          className={clsx(classes.whyPortfolioButton, classes.absoluteCenter)}
          color="secondary"
          component={props => (
            <Link className={classes.whatPortfolioButton} to="/portfolio">
              <motion.button {...props} />
            </Link>
          )}
          to="/portfolio"
          style={whyPortfolioButtonStyle}
        >
          AWESOMENESS <br /> HERE
        </RoundedButton>
      </div>
    </div>
  )
}

export default WhySection
