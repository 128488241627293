import React, { useState } from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import stepsFromRange from "../helpers/stepsFromRange"
import getRatio from "../helpers/getRatio"
import clsx from "clsx"
import classes from "./LetsCreate.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useMediaQuery } from "react-responsive"
import BirdImage from "../gatsbyImages/BirdImage"
import USImage from "../gatsbyImages/USImage"

function LetsCreate({ start, middle, out, end }) {
  const { scrollY } = useViewportScroll()

  const [zIndex, setzIndex] = useState(0)

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  scrollY.onChange(x => {
    if (x > start && x < end) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })

  const range = [start, end]

  const middleRatio = getRatio(start, middle, end)

  const outRatio = getRatio(start, out, end)

  const scale = useTransform(
    scrollY,
    [start, middle, out, end],
    isMobile ? [0.8, 0.9, 0.9, 0.3] : [1, 1, 1, 0.3]
  )

  const opacityOffAfterMiddle = useTransform(
    scrollY,
    [start, start + 1, end, end + 1],
    [0, 1, 1, 0]
  )

  const opacityInOut = useTransform(
    scrollY,
    [start, middle, end, end + 1],
    [0, 1, 1, 0]
  )

  const bubbleOneStyle = {
    x: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile
        ? ["-50%", "-52%", "-52%", "-50%"]
        : ["-30%", "-40%", "-40%", "-63%"]
    ),
    y: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile
        ? ["-50%", "-58%", "-58%", "-38%"]
        : ["-49%", "-49%", "-49%", "-49%"]
    ),
    transformOrigin: "center",
    opacity: opacityInOut,
  }

  const bubbleTwoStyle = {
    x: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile
        ? ["-50%", "-50%", "-50%", "-50%"]
        : ["-55%", "-55%", "-55%", "-55%"]
    ),

    y: useTransform(
      scrollY,
      [start, middle, out, end],
      ["-50%", "-49%", "-49%", "-48%"]
    ),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const manyEditsStyle = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, 0.2, middleRatio, outRatio, 0.8, 1]),
      isMobile
        ? [1000, 800, -120, -120, -500, -1000]
        : [1000, 800, -100, -100, -500, -1000]
    ),
    x: isMobile ? -200 : -100,
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const letsCreateStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, 0.2, middleRatio, outRatio, 0.8, 1]),
      isMobile
        ? [1200, 900, -295, -295, -755, -1200]
        : [-1200, -900, -290, -290, 755, 1200]
    ),
    y: -300,
    scale: 0.8,
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const byAllMeansStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, 0.2, middleRatio, outRatio, 0.8, 1]),
      isMobile
        ? [1200, 800, -260, -260, -800, -1200]
        : [-1200, -800, -260, -260, 800, 1200]
    ),
    y: -230,

    scale: 0.8,
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const birdStyle = {
    x: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile ? [-1600, -400, -400, 800] : [-1600, -600, -600, -1000]
    ),
    y: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile ? [800, -10, -10, -1000] : [800, -100, -100, -800]
    ),
    scale: scale,
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const contactStyle = {
    transformOrigin: "top right",
    scale: useTransform(
      scrollY,
      [middle, out, end],
      isMobile ? [0.8, 0.8, 0.8] : [1, 1, 0]
    ),
    opacity: useTransform(scrollY, [start, middle], [0, 1]),
    transform: "rotate(-20deg)",
    x: useTransform(
      scrollY,
      [start, middle, out, end],
      isMobile ? [500, 5, 5, -900] : [5, 5, 5, 5]
    ),
    y: 200,
  }

  const usStyle = {
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [260, 260, 260, 260] : [1400, 270, 270, 0]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [1000, -380, -380, -1600] : [1000, -370, -370, -1400]
    ),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
    scale: "0.8",
  }

  return (
    <div
      style={{
        zIndex: zIndex,
      }}
      className={classes.letsCreateContainer}
    >
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <motion.div
          style={bubbleOneStyle}
          className={clsx(classes.letsCreateBubbleOne, classes.absoluteCenter)}
        />
        <motion.div
          style={bubbleTwoStyle}
          className={clsx(classes.letsCreateBubbleTwo, classes.absoluteCenter)}
        />
        <motion.div
          style={letsCreateStyle}
          className={clsx(classes.letsCreate, classes.absoluteCenter)}
        >
          let's create together.
        </motion.div>

        <motion.div
          style={byAllMeansStyle}
          className={clsx(classes.byAllMeans, classes.absoluteCenter)}
        >
          By all means
        </motion.div>

        <motion.div
          style={manyEditsStyle}
          className={clsx(classes.manyEdits, classes.absoluteCenter)}
        >
          many edits? <br /> fancy an offer? <br /> let's meet!
        </motion.div>

        <motion.div
          style={birdStyle}
          className={clsx(classes.bird, classes.absoluteCenter)}
        >
          {/* <img src={isMobile ? BirdMobile : Bird} alt="" /> */}
          <BirdImage />
        </motion.div>

        <motion.div
          style={usStyle}
          className={clsx(classes.us, classes.absoluteCenter)}
        >
          {/* <img src={isMobile ? USMobile : US} alt="" /> */}
          <USImage />
        </motion.div>

        <AnchorLink to="/video-editing#contactus" stripHash>
          <motion.div
            style={contactStyle}
            className={clsx(classes.contact, classes.absoluteCenter)}
          >
            contact
          </motion.div>
        </AnchorLink>
      </div>
    </div>
  )
}

export default LetsCreate
