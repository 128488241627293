import React from "react"
import PinkArrow from "../gatsbyImages/PinkArrow"
import BlackArrow from "../gatsbyImages/BlackArrow"
import { motion, useTransform, useViewportScroll } from "framer-motion"

function ScrollArrow({ end }) {
  const { scrollY } = useViewportScroll()
  return (
    <motion.div
      className="-mt-48"
      style={{ opacity: useTransform(scrollY, [-1, end], [1, 0]) }}
    >
      <p className="text-sm mb-4">scroll down for magic</p>
      <div className="w-8 md:w-full mt-16 md:mt-0 m-auto">
        <div>
          <PinkArrow />
        </div>
        <div className="-mt-6">
          <BlackArrow />
        </div>
        <div className="-mt-6">
          <PinkArrow />
        </div>
      </div>
    </motion.div>
  )
}

export default ScrollArrow
