import React, { useState } from "react"
import classes from "./video-editing.module.css"
import SEO from "../components/seo"
import WhoSection from "../components/WhoSection"
import WhatSection from "../components/WhatSection"
import WhySection from "../components/WhySection"
import LetsChat from "../components/LetsChat"
import LetsCreate from "../components/LetsCreate"
import StoryTelling from "../components/StoryTelling"
import FreeStuffSection from "../components/FreeStuff"
import Dropdown from "../components/Dropdown"
import { graphql } from "gatsby"
import LogoImage from "../gatsbyImages/LogoImage"
import ScrollArrow from "../components/ScrollArrow"
import clsx from "clsx"
import Layout from "../components/layout"

const VideoEditingPage = ({ data }) => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  return (
    <Layout>
      {isDropdownOpen && <Dropdown setisDropdownOpen={setisDropdownOpen} />}
      {!isDropdownOpen && (
        <div
          className={classes.logo}
          onClick={e => setisDropdownOpen(!isDropdownOpen)}
        >
          <LogoImage />
        </div>
      )}

      <div className={classes.root} onClick={e => setisDropdownOpen(false)}>
        <SEO title="Home" />

        <div className={clsx(classes.section, "flex flex-col items-center")}>
          <div className={classes.countdownGif}>
            <iframe
              title="video"
              src={data.allContentfulVideoEditing.nodes[0].videoUrl}
              allowfullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              frameBorder="0"
              width="100%"
              height="100%"
            />
          </div>
          <ScrollArrow end={400} />
        </div>

        <div className={classes.section}>
          <WhySection start={400} middle={700} out={1100} end={1400} />
        </div>

        <div className={classes.section}>
          <FreeStuffSection start={1400} middle={1700} out={2100} end={2400} />
        </div>

        <div className={classes.section}>
          <WhatSection start={2400} middle={2700} out={3100} end={3400} />
        </div>

        <div className={classes.section}>
          <StoryTelling start={3400} middle={3700} out={4100} end={4400} />
        </div>

        <div className={classes.section}>
          <WhoSection start={4400} middle={4700} out={5100} end={5400} />
        </div>

        <div className={classes.section}>
          <LetsCreate start={5400} middle={5700} out={6100} end={6400} />
        </div>

        <div className={classes.section}>
          <LetsChat start={6400} end={7000} />
        </div>

        <div className="absolute bottom-0" id="contactus"></div>

        <div className={`bold flex justify-center mb-8 ${classes.footer}`}>
          © {new Date().getFullYear()} MAD JON PRODUCTIONS. ALL RIGHTS RESERVED
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query VideoEditingQuery {
    allContentfulVideoEditing {
      nodes {
        videoUrl
      }
    }
  }
`

export default VideoEditingPage
