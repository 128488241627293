import React, { useState } from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import clsx from "clsx"

import stepsFromRange from "../helpers/stepsFromRange"
import getRatio from "../helpers/getRatio"

import RoundedButton from "./RoundedButton"
import classes from "./WhatSection.module.css"

import { useMediaQuery } from "react-responsive"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import GoProImage from "../gatsbyImages/GoProImage"
import WeddingVideosImage from "../gatsbyImages/WeddingVideosImage"
import ArrowImage from "../gatsbyImages/ArrowImage"
import EditAt2XImage from "../gatsbyImages/EditAt2XImage"

function WhatSection({ start, middle, out, end }) {
  const { scrollY } = useViewportScroll()
  const [zIndex, setzIndex] = useState(0)
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  scrollY.onChange(x => {
    if (x > start && x < end) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })

  const range = [start, end]

  const scale = useTransform(scrollY, [400, 944], [0, 1])

  const middleRatio = getRatio(start, middle, end)

  const outRatio = getRatio(start, out, end)

  const opacityOffAfterMiddle = useTransform(
    scrollY,
    [middle - 1, middle, out, out + 1],
    [0, 1, 1, 0]
  )

  const opacityInOut = useTransform(
    scrollY,
    stepsFromRange(range, [0, 0.1, middleRatio, outRatio, 0.9, 1]),
    [0, 0.8, 1, 1, 0.8, 0]
  )

  const bubbleOneStyle = {
    x: useTransform(
      scrollY,
      [900, middle],
      isMobile
        ? ["-50%", "-50%"]
        : ["calc(-50% + 70px)", "calc(-60% + (-20px))"]
    ),
    y: useTransform(
      scrollY,
      [900, middle],
      isMobile
        ? ["calc(-50% - 80px)", "calc(-50% - 80px)"]
        : ["calc(-50% + (-20px))", "calc(-50% + (-40px))"]
    ),
    transformOrigin: "center",
    opacity: opacityOffAfterMiddle,
  }

  const bubbleTwoStyle = {
    x: "-50%",
    y: "-50%",
    transformOrigin: "center",
    opacity: useTransform(
      scrollY,
      [start, start + 1, end - 2, end - 1],
      [0, 1, 1, 0]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
  }

  const whatWeDoTextStyle = {
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [900, 50, 50, -200]
    ),
    opacity: opacityInOut,
    rotate: "-90deg",
  }

  const whatTextStyle = {
    display: "inline-block",
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["170%", "-33%", "-33%", "-100%"]
    ),
    opacity: opacityInOut,
  }

  const whatParagraphsTextStyle = {
    display: "inline-block",
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 0, 0, 0] : [700, 0, 0, 100]
    ),
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 1, 1, 0] : [1, 1, 1, 0]
    ),
    opacity: opacityInOut,
  }

  const twoImagesStyle = {
    display: "inline-block",
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [-900, 0, 0, -900] : [-900, 0, 0, 900]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [-200, 25, 25, 100] : [-200, 25, 25, -700]
    ),
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [1.35, 1, 1, 1] : [1.35, 1, 1, 1.6]
    ),
  }

  const whatImageOneStyle = {
    display: "inline-block",
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? ["0deg", "0deg", "0deg"] : ["0deg", "0deg", "105deg"]
    ),
    scale: isMobile ? 0.8 : 0.9,
    opacity: opacityInOut,
  }

  const whatImageTwoStyle = {
    display: "inline-block",
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? ["0deg", "0deg", "0deg"] : ["0deg", "0deg", "150deg"]
    ),
    scale: isMobile ? 0.6 : 0.8,
    opacity: opacityInOut,
  }

  const imageThreeStyle = {
    display: "inline-block",
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["40%", "18%", "18%", "-100%"]
        : ["-100%", "-4%", "-4%", "100%"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? ["100%", "30%", "30%", "-100%"] : ["100%", "6%", "6%", "-100%"]
    ),
    rotate: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile
        ? ["215deg", "-15deg", "-15deg", "-300deg"]
        : ["-15deg", "-15deg", "-15deg", "-300deg"]
    ),
    opacity: opacityInOut,
    scale: isMobile ? 0.7 : 0.75,
  }

  const portfolioStyle = {
    display: "inline-block",
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [250, 0, 0, -250]
    ),
    opacity: opacityInOut,
  }

  const arrowImageStyle = {
    x: "-50%",
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 0.7, 0.7, 0] : [0, 0.8, 0.8, 0]
    ),
    rotate: useTransform(scrollY, stepsFromRange(range, [0, 0.6, 0.7, 1]), [
      "0deg",
      "0deg",
      "0deg",
      "20deg",
    ]),
    opacity: opacityInOut,
  }

  return (
    <div className={classes.whatSectionContainer} style={{ zIndex }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <motion.div
          style={bubbleOneStyle}
          className={clsx(classes.whatBubbleOne, classes.absoluteCenter)}
        />
        <motion.div
          style={bubbleTwoStyle}
          className={clsx(classes.whatBubbleTwo, classes.absoluteCenter)}
        />
        <motion.div
          style={{
            scale,
            x: useTransform(scrollY, [945, 1300], ["-50%", "-60%"]),
            y: "-50%",
            transformOrigin: "center",
            width: 720,
            height: 720,
          }}
          className={clsx(classes.absoluteCenter)}
        >
          <div className={classes.whatContentContainer}>
            <motion.span style={whatWeDoTextStyle} className={classes.whatWeDo}>
              <span className={classes.whatWeDoWe}>we</span> do!
            </motion.span>

            <motion.span style={whatTextStyle} className={classes.whatText}>
              <span className="italic">
                <span className={classes.whatTextW}>w</span>
                <span>hat</span>
              </span>
              ?
            </motion.span>

            <div className={classes.whatParagraphs}>
              <motion.div style={whatParagraphsTextStyle}>
                <div style={{ marginBottom: 8 }}>
                  <div>take your footage and tailor it into a story</div>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <div>make the normal stuff extraordinary</div>
                </div>
                <div style={{ marginBottom: 8 }}>
                  edit any kind of video format.
                </div>
                <div>
                  <div>can even re-edit your edit.</div>
                </div>
              </motion.div>
            </div>

            <motion.div style={twoImagesStyle}>
              <motion.div
                className={classes.whatImageOne}
                style={whatImageOneStyle}
              >
                <GoProImage />
              </motion.div>
              <motion.div
                className={classes.whatImageTwo}
                style={whatImageTwoStyle}
              >
                <WeddingVideosImage />
              </motion.div>
            </motion.div>

            <motion.div
              style={imageThreeStyle}
              className={classes.whatImageThree}
            >
              <EditAt2XImage />
            </motion.div>

            <motion.div style={arrowImageStyle} className={classes.arrowImage}>
              <ArrowImage />
            </motion.div>

            <RoundedButton
              className={classes.whatButton}
              component={props => (
                <AnchorLink
                  stripHash
                  className={classes.whatPortfolioButton}
                  to="/portfolio"
                >
                  <motion.button {...props} />
                </AnchorLink>
              )}
              color="yellow"
              style={portfolioStyle}
            >
              OUR WORK
            </RoundedButton>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default WhatSection
