function stepsFromRange (range, steps) {
  const [ start, end ] = range

  const difference = end - start

  return steps.map(
    step => start + (step * difference)
  )
}

export default stepsFromRange
