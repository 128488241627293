import { useTransform, useViewportScroll, motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import stepsFromRange from "../helpers/stepsFromRange"
import getRatio from "../helpers/getRatio"
import styles from "./StoryTelling.module.css"
import RoundedButton from "./RoundedButton"
import { Link, useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"
import { useMediaQuery } from "react-responsive"
import PicturePerfectImage from "../gatsbyImages/PicturePerfectImage"
import VideoStoryTellingImage from "../gatsbyImages/VideoStoryTellingImage"
import ArtImage from "../gatsbyImages/ArtImage"
import PeopleImage from "../gatsbyImages/PeopleImage"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core"

// install Swiper modules
SwiperCore.use([Pagination, Navigation])

const BigBubbleText = ({ middle, type = "primary", reviews }) => {
  const [swiper, setSwiper] = useState(null)
  const [isEnd, setIsEnd] = useState(true)
  const [isBeginning, setIsBeginning] = useState(false)

  useEffect(() => {
    setInterval(() => {
      if (swiper) swiper.slideNext(1000)
    }, 8000)
  }, [swiper])

  return (
    <>
      <Swiper
        onSwiper={s => {
          setSwiper(s)
          setIsBeginning(s.isBeginning)
          setIsEnd(s.isEnd)
        }}
        onSlideChange={s => {
          setIsBeginning(s.isBeginning)
          setIsEnd(s.isEnd)
        }}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        className={clsx(styles.bigBubbleText, "mySwiper")}
      >
        {reviews?.map((review, idx) => {
          return (
            <SwiperSlide key={idx}>
              <a
                href={`https://www.instagram.com/${review.userName}`}
                target="_blank"
              >
                <div className="flex items-center justify-center mb-1">
                  <img src={review.image.fluid.src} alt="" width="50" />
                  <div className="ml-2" style={{ fontSize: "1.2em" }}>
                    @{review.userName}
                  </div>
                </div>
              </a>
              <p>"{review.review.review}"</p>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

function StoryTelling({ start, middle, out, end }) {
  const data = useStaticQuery(graphql`
    query StoryTellingQuery {
      allContentfulEditedByUs {
        nodes {
          videoUrl
        }
      }
      allContentfulTestimonial {
        nodes {
          userName
          review {
            review
          }
          image {
            fluid {
              src
            }
          }
        }
      }
    }
  `)
  const { scrollY } = useViewportScroll()
  const [zIndex, setzIndex] = useState(0)
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
  const reviews = data.allContentfulTestimonial.nodes

  scrollY.onChange(x => {
    if (x > start && x < end) {
      setzIndex(1)
    } else {
      setzIndex(-1)
    }
  })
  const range = [start, end]
  const middleRatio = getRatio(start, middle, end)

  const outRatio = getRatio(start, out, end)

  const opacityOffAfterMiddle = useTransform(
    scrollY,
    [middle - 1, middle, out, out + 1],
    [0, 1, 1, 0]
  )
  const opacityInOut = useTransform(
    scrollY,
    stepsFromRange(range, [0, 0.1, middleRatio, outRatio, 0.9, 1]),
    [0, 0.8, 1, 1, 0.8, 0]
  )

  const bigBlackBubbleStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
    transformOrigin: isMobile ? "50% 110%" : "100% 100%",
  }

  const pinkBubbleStyles = {
    opacity: opacityOffAfterMiddle,
  }

  const smallBlackBubbleStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      [0, 1, 1, 0]
    ),
    transformOrigin: isMobile ? "82.5% -35%" : "0% 15%",
  }

  const happyTextStyles = {
    opacity: opacityInOut,
    transformOrigin: "right",
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? [1, 1] : [0, 1]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [0, 0, 0] : ["0px", "0px", "-300px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [300, 0, 0, -300] : ["0%", "0%", "0px", "-50%"]
    ),
  }

  const peopleImageStyles = {
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? [0.9, 0.9] : [0, 1]
    ),
    opacity: opacityInOut,
    // transformOrigin: "right",
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [110, 110, 0] : ["100px", "100px", "-300px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [300, 160, 160, -300] : ["0%", "70%", "70%", "-20%"]
    ),
  }

  const picturePerfectImageStyles = {
    opacity: opacityInOut,
    transformOrigin: "0% 100%",
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [middleRatio, outRatio, outRatio, outRatio] : [0, 0.8, 0.8, 1]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [100, -50, -50, -100] : ["-100%", "-25%", "-25%", "-100%"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["100%", "-33%", "-33%", "100%"]
    ),
  }

  const moreStuffButtonStyles = {
    opacity: opacityInOut,
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      ["200px", "0px", "0px", "-500px"]
    ),
  }

  const videoPlaceholderImageStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0.5, 1, 1, 0] : [0, 1, 1, 1.2]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [0, 0, 0] : ["0px", "0px", "-750px"]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [-400, 0, 0, 0] : ["0px", "0px", "0px", "50px"]
    ),
    transformOrigin: isMobile ? "0% 50%" : "0% 100%",
  }

  const artImageStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0.8, 0.9, 0.9, 1.2] : [0, 1, 1, 1.2]
    ),
    x: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio]),
      isMobile ? ["-100%", "15%"] : [50, 50]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [100, 160, 160, -500] : ["0px", "170px", "170px", "-500px"]
    ),
    transformOrigin: "center",
  }

  const bigBubbleTextStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 1, 1, 0] : [0, 1, 1, 1]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [0, 0, 0] : ["0px", "0px", "-500px"]
    ),
    transformOrigin: isMobile ? "50% 200%" : "0% 100%",
  }

  const videoStoryTellingImageStyles = {
    opacity: opacityInOut,
    scale: useTransform(
      scrollY,
      stepsFromRange(range, [0, middleRatio, outRatio, 1]),
      isMobile ? [0, 0.85, 0.85, 0] : [0, 0.85, 0.85, 1]
    ),
    y: useTransform(
      scrollY,
      stepsFromRange(range, [middleRatio, outRatio, 1]),
      isMobile ? [0, 0, 0] : ["0px", "0px", "-400px"]
    ),
  }

  return (
    <div style={{ zIndex }} className={styles.storyTellingContainer}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {/* big circle */}
        <div className={styles.bigBlackBubbleContainer}>
          <motion.div
            className={styles.bigPinkBubble}
            style={pinkBubbleStyles}
          />
          <motion.div
            className={styles.bigBlackBubble}
            style={bigBlackBubbleStyles}
          />
          <motion.span className={styles.happyText} style={happyTextStyles}>
            happy
          </motion.span>
          <motion.div className={styles.peopleImage} style={peopleImageStyles}>
            <PeopleImage />
          </motion.div>
          <motion.div
            style={bigBubbleTextStyles}
            className={styles.bigBubbleTextContainer}
          >
            <BigBubbleText middle={middle} reviews={reviews} />
          </motion.div>
          <motion.div className={styles.artImage} style={artImageStyles}>
            <ArtImage />
          </motion.div>
          <motion.div
            className={styles.videoStoryTellingImage}
            style={videoStoryTellingImageStyles}
          >
            <VideoStoryTellingImage />
          </motion.div>
        </div>

        {/* small circle */}
        <div className={styles.smallBlackBubbleContainer}>
          <div>
            <motion.div
              className={styles.smallPinkBubble}
              style={pinkBubbleStyles}
            />
            <motion.div
              className={styles.smallBlackBubble}
              style={smallBlackBubbleStyles}
            />
            <motion.div
              className={styles.picturePerfectImage}
              style={picturePerfectImageStyles}
            >
              <PicturePerfectImage />
            </motion.div>
            <motion.div
              className={styles.videoPlaceholderImage}
              style={videoPlaceholderImageStyles}
            >
              <div className={styles.videoStoryTellingContainer}>
                <iframe
                  allowFullScreen
                  src={data.allContentfulEditedByUs.nodes[0].videoUrl}
                  frameBorder="0"
                ></iframe>
              </div>
            </motion.div>
            <RoundedButton
              className={styles.moreButton}
              component={props => (
                <Link className={styles.moreStuffButton} to="/portfolio">
                  <motion.button {...props} />
                </Link>
              )}
              color="secondary"
              style={moreStuffButtonStyles}
            >
              MORE STUFF
            </RoundedButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryTelling
